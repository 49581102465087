import { classNames } from "~/utils/etc";

type DividerProps = {
  bgColor?: string;
};

export function Divider(props: DividerProps) {
  return (
    <div
      class="h-px w-full  "
      style={{
        "background-color": props.bgColor,
      }}
      classList={{
        "bg-baseTertiaryDark dark:bg-basePrimaryMedium": !props.bgColor,
      }}
    ></div>
  );
}

type DividerV2Props = {
  class: string;
};

// To support tailwind classes for different background colors
export function DividerV2(props: DividerV2Props) {
  return <div class={classNames("h-px w-full", props.class)}>&nbsp;</div>;
}
