type SpacerProps = (
  | { height: number; width?: never }
  | { width: number; height?: never }
) & { class?: string };

export function Spacer(props: SpacerProps) {
  if ("height" in props) {
    return (
      <div class={props.class} style={{ height: props.height + "px" }}>
        &nbsp
      </div>
    );
  }
  return <div class={props.class} style={{ width: props.width + "px" }} />;
}
